import * as React from "react"
import * as writing_styles from '../styles/writing.module.css'


// markup
const NotFoundPage = () => {
  return (
    <div className={writing_styles.container}>404. Return <a href='/'>home</a>. </div>
  )
}

export default NotFoundPage
